var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1 d-flex"},[_vm._v("Muut sopimukset")]),_c('small',[_vm._v("Voit luoda muita allekirjoittavia sopimuksia esim. avaimenluovuksen yhteydessä.")]),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_c('v-spacer'),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/other-contract/create-document"}},[(_vm.isAuthorized('otherContract', 'create'))?_c('v-btn',{staticClass:"mb-2 mr-1",attrs:{"color":"info"}},[_vm._v("Uusi sopimus")]):_vm._e()],1)],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Hae kaikista kentistä...","single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col')],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contracts,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.contractNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.contractNumber)+" ")])]}},{key:"item.tenant",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/tenant/overview/" + (item.tenant._id))}},[_c('span',[_vm._v(_vm._s(item.tenant.name)+" ")])])]}},{key:"item.otherTenants",fn:function(ref){
var item = ref.item;
return _vm._l((item.otherTenants),function(tenant,index){return _c('div',{key:'a' + index},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/tenant/overview/" + (tenant._id))}},[_vm._v(" "+_vm._s(tenant.name)+" ")])],1)})}},{key:"item.landlords",fn:function(ref){
var item = ref.item;
return _vm._l((item.landlords),function(landlord,index){return _c('div',{key:'a' + index},[_vm._v(" "+_vm._s(landlord.landlordId.name)+" ")])})}},{key:"item.apartment",fn:function(ref){
var item = ref.item;
return [(item.apartment)?_c('span',[_vm._v(" "+_vm._s(item.apartment.address)),(item.apartment.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(item.apartment.apartmentNumber))]):_vm._e()]):_vm._e()]}},{key:"item.keys",fn:function(ref){
var item = ref.item;
return _vm._l((item.keys),function(key,index){return _c('span',{key:'a' + index},[_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v(_vm._s(key.code))]),(index < item.keys.length - 1)?_c('span',[_vm._v(" | ")]):_vm._e()])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.itemLoading)?_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('otherContract', 'create'))?_c('v-icon',{attrs:{"color":_vm.getSignatureColor(item),"title":"Allekirjoitus","small":""},on:{"click":function($event){_vm.showSelectSignatureDialog = true;
              _vm.setToSignatureState(item);}}},[_vm._v("mdi-draw")]):_vm._e(),(_vm.isAuthorized('otherContract', 'read'))?_c('v-icon',{attrs:{"title":"Lataa sopimus","small":""},on:{"click":function($event){_vm.showDownloadLoader = true;
              _vm.downloadPdf(item);}}},[_vm._v("mdi-file-pdf")]):_vm._e(),(!_vm.isCompleted(item) && _vm.isAuthorized('otherContract', 'edit'))?_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(!_vm.isCompleted(item) && _vm.isAuthorized('otherContract', 'delete'))?_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1):_vm._e(),(item.itemLoading)?_c('v-row',[_c('v-col',[_c('small',[_vm._v("Ladataan sopimusta...")]),_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","rounded":"","height":"6"}})],1)],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei sopimuksia")])]},proxy:true}],null,true)})],1),_c('select-signature',{model:{value:(_vm.showSelectSignatureDialog),callback:function ($$v) {_vm.showSelectSignatureDialog=$$v},expression:"showSelectSignatureDialog"}}),_c('download-loader',{attrs:{"loading":_vm.downloading,"text":"Odota, ladataan sopimusta..."},model:{value:(_vm.showDownloadLoader),callback:function ($$v) {_vm.showDownloadLoader=$$v},expression:"showDownloadLoader"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }